.post {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  margin: 10px auto;

  @media #{$media-size-tablet} {
    max-width: 600px;
  }

  &-date {
    &:after {
      content: '—';
    }
  }

  &-title {
    font-size: 2.625rem;
    margin: 10 0 20px;
    text-align: center;

    @media #{$media-size-phone} {
      font-size: 2rem;
    }

    a {
      text-decoration: none;
      text-align: center;
      width: 100%;
    }
  }

  &-tags {
    display: block;
    margin-bottom: 20px;
    font-size: 1rem;
    opacity: .5;

    a {
      text-decoration: none;
    }
  }

  &-content {
    // margin-top: 10px;
    text-align: justify;
    h2 {
      text-align: center;
    }
    // text-indent: 5%;
  }

  &-cover {
    border-radius: 8px;
    margin: 40px -50px;
    width: 860px;
    max-width: 860px;

    @media #{$media-size-tablet} {
      margin: 20px 0;
      width: 100%;
    }
  }

  &-info {
    margin-top: 0;
    margin-bottom: 2rem;
    font-size: 1rem;
    line-height: normal;
    @include dimmed;
    text-align: right;

    p {
      margin: .8em 0;
    }

    a:hover {
      border-bottom: 1px solid white;
    }

    svg {
      margin-right: .2em;
    }

    .tag {
      margin-right: .5em;

      &::before {
        content: "#"
      }
    }

    .feather {
      display: inline-block;
      vertical-align: -.125em;
      width: .9em;
      height: .9em;
    }
  }

  .flag {
    border-radius: 50%;
    margin: 0 5px;
  }
}

.pagination {
  margin-top: 20px;

  &__title {
    display: flex;
    text-align: center;
    position: relative;
    margin: 30px 0 20px;

    &-h {
      margin: 0 auto;
      padding: 5px 10px;
      background: $light-background;
      color: $light-color-secondary;
      font-size: .8rem;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: .1em;
      z-index: 1;

      .dark-theme & {
        background: $dark-background;
        color: $dark-color-secondary;
      }
    }

    hr {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      margin-top: 15px;
      z-index: 0;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      text-decoration: none;
      font-weight: bold;
    }
  }
}

.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $light-background-secondary;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  max-width: 40%;
  padding: 0;
  cursor: pointer;
  appearance: none;

  .dark-theme & {
    background: $dark-background-secondary;
  }

  + .button {
    margin-left: 10px;
  }

  a {
    display: flex;
    padding: 8px 16px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &.next .button__icon {
    margin-left: 8px;
  }

  &.previous .button__icon {
    margin-right: 8px;
  }
}

.footnotes {
  font-size: .9em;
}

.video-player {
  text-align: center;
}


.story {
  font-size: 1.5rem;
  @media #{$media-size-phone} {
    font-size: 1.35rem;
  }
}

.story-p-indent {
  margin-top:0;
  margin-bottom: 0;
  text-indent: 5%;
  @media #{$media-size-phone} {
    text-indent: 10%;
  }
}

.notice {
  color: $light-color;
  background-color: $light-background-secondary;
  font-size: .9em;
  border-radius: 5px;
  line-height: 1.2em;
  border: 2px solid $dark-color-secondary;
  padding: 12px;
  width: auto;
  box-sizing: border-box;
  outline:0;

  .dark-theme & {
      border: 2px solid inherit;
      background-color: $dark-background-secondary;
      color: inherit;
      }

}

#progress-bar-container {
  height: .9rem;
  position: sticky;
  top: 0;
  background-color: $light-background;
  .dark-theme & {
    background-color: $dark-background;
  }
}

#progress-bar {
  --scrollAmount: 0%;
    background-color: $light-color-secondary;
  .dark-theme & {
    background-color: $dark-color-secondary;
  }
  width: var(--scrollAmount);
  height: .5rem;
  top: .2rem;
  position: sticky;
}