.typewriter {
    display: inline-block;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid $dark-color; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    margin-block-end: 2em;
    letter-spacing: 0.06em; /* Adjust as needed */
    animation: 
      typing 1.5s steps(17, end),
      blink-caret .8s step-end infinite;
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 75% } //not 100% because with letter-spacing and display inline-block it moves over a few pixels
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: $dark-color; }
  }