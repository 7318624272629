@font-face {
  font-family: 'VolumeTC';
  src: url('fonts/VolumeTC-Regular.woff2') format('woff2'),
      url('fonts/VolumeTC-Regular.woff') format('woff'),
      url('fonts/VolumeTC-Regular.ttf') format('truetype'),
      url('fonts/VolumeTC-Regular.svg#VolumeTC-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'VolumeTC';
  src: url('fonts/VolumeTC-Italic.woff2') format('woff2'),
      url('fonts/VolumeTC-Italic.woff') format('woff'),
      url('fonts/VolumeTC-Italic.ttf') format('truetype'),
      url('fonts/VolumeTC-Italic.svg#VolumeTC-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'VolumeTCSans';
  src: url('fonts/VolumeTCSans-Regular.woff2') format('woff2'),
      url('fonts/VolumeTCSans-Regular.woff') format('woff'),
      url('fonts/VolumeTCSans-Regular.ttf') format('truetype'),
      url('fonts/VolumeTCSans-Regular.svg#VolumeTCSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}